import * as React from 'react';
import NewLayout from 'components/NewLayout';
import { graphql } from 'gatsby';
import { withIntl } from 'LanguageProvider/withLanguageProvider';
import translation from 'intl';
import Commercial from 'components/pages/solutionByTypes/Commercial';
import SEOGraphQL from 'components/SEOGraphQL';
import { PageSEOType } from 'components/types';
import { PREFIX_CANONICAL_URL } from 'components/constant';
import BreadcrumbList from 'components/structuredData/BreadcrumbList';

const pageUrl = `${PREFIX_CANONICAL_URL}/solutions-for-commercial-construction`;

const CommercialPage = () => {
  return (
    <NewLayout headerStyle="transparentWithDarkText">
      <BreadcrumbList
        pageLabelId="structuredData.commercial"
        pageUrl={pageUrl}
      />
      <Commercial />
    </NewLayout>
  );
};
export default withIntl(translation)(CommercialPage);

export const Head = ({
  data,
}: {
  data: {
    allStrapiSolutionByType: {
      nodes: PageSEOType[];
    };
  };
}) => {
  return (
    <SEOGraphQL
      data={data.allStrapiSolutionByType.nodes?.[0]}
      canonicalURL={pageUrl}
    />
  );
};
export const query = graphql`
  query {
    allStrapiSolutionByType(
      filter: { ONLY_FOR_DEV_pageId: { eq: "commercial" } }
    ) {
      nodes {
        seo {
          preventIndexing
        }
        SEO {
          metaDescription
          metaTitle
          metaSocial {
            title
            description
            image {
              data {
                attributes {
                  url
                }
              }
            }
          }
        }
      }
    }
  }
`;
